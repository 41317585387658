export default{
    props:{
        show: {
            type: Boolean,
            default: true
        },
    },
    data(){
        return{
            
        }
    },
    mounted(){
        
    },
    methods:{
        // 点击确定
        onConfirm(){
            this.$emit('close')
        },
        // 点击关闭
        onClose(){
            this.$emit('close')
        }
    }
}