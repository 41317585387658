//  商品列表
import { mapState } from "vuex";
import RetailProductItem from "@/components/retail-order/retail-product-item/index.vue";
import Sku from "./components/sku/index.vue";
import { getRetailProducts } from "@/service/retail-order/index.js";
import { getToken } from "@/utils/localStorage.js";
import { Toast } from "vant";

export default {
  name: "retail-order-home-page",
  components: { RetailProductItem, Sku },
  data() {
    return {
      products: [],
      showSku: false, //显示sku弹窗
    };
  },
  computed: {
    ...mapState({
      logo: (state) =>
        state.permission.permission["retail-order"] &&
        state.permission.permission["retail-order"].logo,
    }),
    //  选中下单的商品
    selectedProducts() {
      return this.products.filter((p) => p.quantity > 0);
    },
    //  已选商品件数
    sumCount() {
      return this.selectedProducts.reduce((sum, p) => {
        return sum + Number(p.quantity);
      }, 0);
    },
  },
  methods: {
    //  跳转到发货页面
    toSend(type) {
      if (!this.selectedProducts.length) {
        Toast("至少选中一个商品才发货～");
        return;
      }
      //  将选中的商品信息保存到vuex中
      this.$store.commit("retail/setField", {
        productList: this.selectedProducts,
        type: type === "juniorList" ? "0" : "1",
      });
      this.$router.push({ name: "retail-address-list", query: { type } });
    },

    // 点击sku
    onSku(){
      this.showSku = true
    },

  },
  created() {
    //  清除预订单
    this.$store.dispatch("retail/reset");
    getRetailProducts({ token: getToken() })
      .then((res) => {
        this.products = res || [];
      })
      .catch((err) => {
        console.error(err);
        this.products = [];
      });
  },
};
